import React, { useEffect, useState } from 'react'
import '../UserManagement/UserManagement.css'
import editIcon from '../../assets/edit.svg.svg'
import deleteIcon from '../../assets/delete.png'
import Modal from "react-modal";
import userProfile from '../../assets/userProfile.svg'
import searchIcon from '../../assets/searchLogo.svg'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant';
import { PortalUserRole, Url } from '../../EnvoirnmentSetup/APIs';
import { emailValidationForJobPoster, field, onlyAlphabets, onlyNumber } from '../../Validation/Validation';
import Lottie from "lottie-react";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import nodata from '../../assets/nodata.png'
import loader from "../../../src/assets/PANLoader.json";
import { useAuthContext, useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from '../../Context/Context';
import deleteIcon2 from '../../assets/deleteIcon.svg'
import closeIcon from '../../assets/closeIcon.svg'
import { useNavigate } from 'react-router-dom';
import breadCrumbArrow from '../../assets/backArrowGray.svg'
import nodatafound from '../../assets/nodata.png'
import confirmationLogo from '../../assets/confirmLogo.jpg'
import LicenseModal from '../../components/LicenseModal/LicenseModal';
import { getAllLicensesInfo } from '../../constants/constantFunctions';

const UserManagement = () => {
  const [editUMModal, setEditUMModal] = useState(false)
  const [editUser,setEditUser] = useState(false)
  const [countryCode, setCountryCode] = useState([])
  const [popupMsg, setPopupMsg] = useState("")
  const [errPopup1, setErrPopup1] = useState(false)
  const [successPopup,setSuccessPopup] = useState(false)
  const [newUserDetails, setNewUserDetails] = useState({fullName: field, cntryCode: "249", mno: field, email: field})
  const [teamList, setTeamList] = useState([])
  const [teamList2, setTeamList2] = useState([])
  const [searchVal,setSearchVal] = useState("")
  const [fetching, setFetching] = useState(false);
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const [deletemodalIsOpen,setDeletemodalIsOpen] = useState(false)
  const [userIdForDelete,setUserIdForDelete] = useState()
  const [fetchAgain,setFetchAgain] = useState(false)
  const [editUserId,setEditUserID] = useState("")
  const [managerLicenseInfo,setManagerLicenseInfo] = useState({})
  const {subMenu, setSubMenu, subMenuColor, setSubMenuColor} = useSelectedSubMenuContext();
  const {subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs} = useSelectedSubMenuJobContext();
  const [confirmActivateUser,setConfirmActivateUser] = useState(false)
  const [openPurchaseModal,setOpenPurchaseModal] = useState(false)
  const { userDetails, setUserDetails } = useAuthContext();

  const navigate = useNavigate()
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const customStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: window?.screen?.width>1020? "35%" : "85%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "25px",
      minWidth: "35%"
    },
  };
  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  useEffect(() => {
    setFetching(true)
    GetAllTeamMates()

      fetch(Url.CountryCodes, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
        .then((resp) => resp.json())
        .then((res) => {
          console.log("country code api response", res);
          setCountryCode(res?.DATA)
          setFetching(false)
        })
        .catch((e) => {
          console.log(e);
          setFetching(false)
        })
    setSelectedCard(6)
    getAllLicensesInfo(userDetails,setUserDetails)
    setSubMenu(true)
    setSubMenuColor(2)
    setSubMenuJob(false)
    setSubMenuColorMyJobs(0)
  }, [fetchAgain])
  
  useEffect(()=>{
    setTeamList(teamList2?.filter((item)=>{
        return(
            (item?.fullName?.toString().toLowerCase())?.includes(searchVal?.toLowerCase()) ||
            (item?.combineMobilenumber?.toString().toLowerCase())?.includes(searchVal?.toLowerCase()) ||
            (item?.userName?.toString().toLowerCase())?.includes(searchVal?.toLowerCase()) ||
            (item?.status?.toString().toLowerCase())?.includes(searchVal?.toLowerCase()) 
        )
    }))
  },[searchVal])

  const GetAllTeamMates = () => {
    setFetching(true)
    //   console.log("date",moment(fromDate).format("yyyy-MM-DD"))
    try {
      let token = sessionStorage.getItem("token");

      fetch(Url?.GetAllTeamMates, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("userdata*****",res)
          if (res.SUCCESS) {
           let newData = res.DATA?.map((i)=>{
           return {
              ...i, combineMobilenumber: "+"+i?.countryCode?.countryCode+" "+i?.mobileNumber
            }
           })

           setTeamList(newData)
           setTeamList2(newData)
           setFetching(false)
          }
        });
    } catch (error) {
      console.log("Experience", error);
      setFetching(false)
    }
  };

  const getUserCompleteDetails = (editUserId) => {
    setFetching(true)
    let token = sessionStorage.getItem("token");
      fetch(Url.getuser+"/"+editUserId, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,

        }
      })
        .then((resp) => resp.json())
        .then((res) => {
          setFetching(false)
          console.log("userData....", res);
          setEditUserID(editUserId)
          setNewUserDetails({fullName: onlyAlphabets('Full Name', res?.DATA?.fullName), cntryCode: res?.DATA?.countryCode?.countryCodeId, mno: onlyNumber(res?.DATA?.mobileNumber, 'Mobile Number'), email: emailValidationForJobPoster("Corporate Email", res?.DATA?.userName)})
          setEditUser(true)
          setEditUMModal(true)
        })
        .catch((e) => {
          console.log(e);
        })
  }

const handleDeleteUser = (item) => {
  setUserIdForDelete(item?.userId)
  setDeletemodalIsOpen(true)
}
const handleActiveUser = (item) => {
  setUserIdForDelete(item?.userId)
  setConfirmActivateUser(true)
}
const handleCloseEditModal = () => {
  setNewUserDetails({...newUserDetails,fullName:"",mno:"",email:"",cntryCode:"249"})
  setEditUser(false)
  setEditUMModal(false)
}

const handleRegInputChange = (e, field) => {
  console.log(e.target.value)
   if (field == "cntryCode") {
    setNewUserDetails({ ...newUserDetails, cntryCode: e?.target?.value })
  }
}

const AddUser = () => {
  if(!(!!newUserDetails?.fullName?.fieldValue)){
    setPopupMsg("Full Name is required.")
    setErrPopup1(true)
  }
  else if(!newUserDetails?.fullName?.isValidField){
    setPopupMsg(newUserDetails?.fullName?.errorField)
    setErrPopup1(true)
  }
  else if(newUserDetails?.mno?.fieldValue.length<4){
    setPopupMsg("Minimum length for mobile no. is 4")
    setErrPopup1(true)
  }
  else if (!(!!newUserDetails?.mno?.fieldValue)){
    setPopupMsg("Mobile No. is required.")
    setErrPopup1(true)
  }
  else if (!newUserDetails?.mno?.isValidField) {
    setPopupMsg(newUserDetails?.mno?.errorField )
    setErrPopup1(true)
  }
  else if(!(!!newUserDetails?.email?.fieldValue)){
    setPopupMsg("Email is required.")
    setErrPopup1(true)
  }
   else if(!newUserDetails?.email?.isValidField){
    setPopupMsg(newUserDetails?.email?.errorField)
    setErrPopup1(true)
  }else{
    const UserBody = {
      web: true,
      fullName: newUserDetails?.fullName?.fieldValue,
      mobileNumber: newUserDetails?.mno?.fieldValue,
      userName: newUserDetails?.email?.fieldValue,
      countryCode: {
          countryCodeId: newUserDetails?.cntryCode
      },
      roles: [
          {
              name: "ROLE_RECRUTER"
          }
      ]
  }
  
  try {
    setFetching(true)
    let token = sessionStorage.getItem("token");
  
    fetch(Url.AddTeamMate, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(UserBody)
    }).then((resp) => resp.json())
      .then((res) => {
        console.log("register api response", res);
        if (res?.SUCCESS == true) {
          getAllLicensesInfo(userDetails,setUserDetails)
          setNewUserDetails({fullName: field, cntryCode: "249", mno: field, email: field})
          setEditUMModal(false)
          setPopupMsg("Recruiter added successfully.")
          setSuccessPopup(true)
          GetAllTeamMates()
  
        }
        else{
          setPopupMsg(res?.message)
          setErrPopup1(true)
        }
        setFetching(false)
      })
      .catch((e) => {
        console.log(e);
        setFetching(false)
      })
  }
  catch (e) {
    console.log(e);
    setFetching(false)
  }
  }
}

const updateUser = () => {
  if(!(!!newUserDetails?.fullName?.fieldValue)){
    setPopupMsg("Full Name is required.")
    setErrPopup1(true)
  }
  else if(!newUserDetails?.fullName?.isValidField){
    setPopupMsg(newUserDetails?.fullName?.errorField)
    setErrPopup1(true)
  }
  else if (!(!!newUserDetails?.mno?.fieldValue)){
    setPopupMsg("Mobile No. is required.")
    setErrPopup1(true)
  }
  else if (!newUserDetails?.mno?.isValidField) {
    setPopupMsg(newUserDetails?.mno?.errorField )
    setErrPopup1(true)
  }
  else if(newUserDetails?.mno?.fieldValue.length<4){
    setPopupMsg("Minimum length for mobile no. is 4")
    setErrPopup1(true)
  }
  else if(!(!!newUserDetails?.email?.fieldValue)){
    setPopupMsg("Email is required.")
    setErrPopup1(true)
  }
   else if(!newUserDetails?.email?.isValidField){
    setPopupMsg(newUserDetails?.email?.errorField)
    setErrPopup1(true)
  }else{
    const UserBody = {
      userId:editUserId,
      web: true,
      fullName: newUserDetails?.fullName?.fieldValue,
      mobileNumber: newUserDetails?.mno?.fieldValue,
      userName: newUserDetails?.email?.fieldValue,
      countryCode: {
          countryCodeId: newUserDetails?.cntryCode
      },
      roles: [
          {
              name: "ROLE_RECRUTER"
          }
      ]
  }
  console.log({UserBody})
  try {
    setFetching(true)
    let token = sessionStorage.getItem("token");
  
    fetch(Url.AddTeamMate, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(UserBody)
    }).then((resp) => resp.json())
      .then((res) => {
        console.log("register api response", res);
        if (res?.SUCCESS == true) {
          setNewUserDetails({fullName: field, cntryCode: "249", mno: field, email: field})
          setEditUMModal(false)
          setPopupMsg("Recruiter updated successfully.")
          setSuccessPopup(true)
          GetAllTeamMates()
  
        }
        else{
          setPopupMsg(res?.message)
          setErrPopup1(true)
        }
        setFetching(false)
      })
      .catch((e) => {
        console.log(e);
        setFetching(false)
      })
  }
  catch (e) {
    console.log(e);
    setFetching(false)
  }
  }
}

const onTextChange = (fields, val) => {
  //console.log(fields)
  switch (fields) {
    case 'Full Name':
      setNewUserDetails({
        ...newUserDetails,
        fullName: onlyAlphabets(fields, val),
      });
      break;
    case 'Email Address':
      setNewUserDetails({
        ...newUserDetails,
        email:emailValidationForJobPoster(fields, val),
      });
      break;
    case 'Mobile Number':
        if(val?.length<=10){
            setNewUserDetails({
                ...newUserDetails,
                mno: onlyNumber(val, fields, 10),
              })
        };
      break;
  }
};

const handleActivateUser = ()=>{
  let body = {
      userId: userIdForDelete
  }
  try{
    setFetching(true)
      fetch(Url?.activateUser, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        body: JSON.stringify(body),
      }).then((resp)=>resp.json())
      .then((res)=>{
      if(res.SUCCESS){
         setFetching(false)
         setConfirmActivateUser(false)
         setPopupMsg("User Activated Successfully.")
         setSuccessPopup(true)
         setFetchAgain(new Date())
      }else{
        setFetching(false)
        setPopupMsg(res?.message)
        setErrPopup1(true)
      }
      })
      .catch((e)=>{
        setFetching(false)
        console.log(e);
      })
    }catch(e){
      setFetching(false)
      console.log(e);
    }
  }


const handleNavigateLinkedIn = (item) => {
  if(!!item?.linkedinProfile){
    window.open(item?.linkedinProfile);
  }
}

function closeDeleteModal() {
  setDeletemodalIsOpen(false);
}

const handleConfirmDeleteUser = () => {
  try{
    setFetching(true)
     fetch(Url.deletePoster.replace("{userId}",userIdForDelete),{
      method:"DELETE",
      headers:{
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token")
      }
     })
     .then((resp)=>resp.json())
     .then((res)=>{
      console.log(res);
      if(res?.SUCCESS){
        setDeletemodalIsOpen(false)
        setPopupMsg("Recruiter deleted successfully.")
        setFetchAgain(!fetchAgain)
        setSuccessPopup(true)
      }
      else{
        setPopupMsg(res?.message)
        setErrPopup1(true)
        setDeletemodalIsOpen(false)
      }
      setFetching(false)
     })
     .catch((e)=>{
      console.log(e);
      setFetching(false)
     })
  }
  catch(e){
     console.log(e);
     setFetching(false)
  }
}
  const handleAddUser = () => {
    // if(userDetails?.userLicense?.remainingUser == 0){
    //   setPopupMsg(`No more licenses available. Buy additional licenses to activate recruiters.`)
    //   setErrPopup1(true)
    // }
    // else{
      setEditUMModal(true);
      setEditUser(false)
    // }
  }
  return (
    <>{PortalUserRole=="ROLE_MANAGER"?
    <div>
       {/* <div className="appcntRepoFixedContent">
        <div className="applcntRepoOuterDiv" style={{ marginTop: "5px" }}>
          <p style={{ color: '#007749', fontSize: '1.5rem', marginTop: '0px',marginBottom:"5px" }}>Poster's List</p>
        </div> 
       </div> */}
       <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: ' 5px 13px 0',marginTop:0}}>
        <p className="breadCrumbDiv1p" style={{marginBottom:"5px"}}>Recruiter's List</p>
        <ul className="breadCrumb">
        <li className="breadcrumbDiv2"><img className="breadcrumbDiv2Img" src={breadCrumbArrow} alt="" />
        <span onClick={()=>navigate(-1)} style={{color:"#007749"}}>Recruiter's List</span>
        </li>
        </ul>
      </div>

       <div className="appcntRepoSec2Div2 UMSearchAndAddUserWrapper" style={{justifyContent:"end",margin:"0 auto"}}>
         {/*  <div className="EntryCount entryCountNew">
            <p>Show</p>
            <div></div>
            <select
              className="selectEntry selectEntryNew"
            //   value={pageEntries}
            //   onChange={(e) => {
            //     setPageEntries(Number(e.target.value));
            //     setPageSize(Number(e.target.value));
            //     setSelectedPageNumber({
            //       actualNumber: 1,
            //       gotoNumber: 1,
            //     });
            //     sessionStorage.setItem(
            //       "pageEntriesLength",
            //       JSON.stringify(e.target.value)
            //     );
            //   }}
            >
              {[50, 100]?.map((pageSizeOption) => (
                <option key={pageSizeOption} value={pageSizeOption}>
                  1-{pageSizeOption}
                </option>
              ))}
            </select>
          </div> */} 

          <div className="appcntRepoSearchSortDiv">
            <div className="appcntRepoSearchDiv UMsearchDiv" 
            // style={{padding:page.length == 0 ? "5px" : "0px",width:page.length == 0 ? "300px":"250px"}}
            >

              <div className="appcntRepoImgDiv">
                <img src={searchIcon} />
              </div>

              <input
                type="text"
                placeholder="Search here..."
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
                className="appcntRepoSearchInput"
              // className="appcntRepoSearch"
              />
            </div>
          </div>
        <button onClick={handleAddUser}>Add User</button>
        </div>
         
       <div className="companyCountWrapper">
        <span className="companyCount">
          <span>Total Users</span>
          <span style={{color:'#202020',backgroundColor:"#D9D9D9"}}>{userDetails?.userLicense?.allocatedUser}</span>
        </span>
        <span className="companyCount">
          <span>Registration Pending</span>
          <span style={{color:"#FF6B00",backgroundColor:"#FFE0CB"}}>{userDetails?.userLicense?.pendingUser}</span>
        </span>
        <span className="companyCount">
          <span>Inactive Users</span>
          <span style={{color:"#BC0000",backgroundColor:'#FFD5DC'}}>{userDetails?.userLicense?.inactiveUser}</span>
        </span>
        <span className="companyCount">
          <span>Licenses Used</span>
          <span style={{color:"#05B221",backgroundColor:"#EAFFED"}}>{userDetails?.userLicense?.activeUser}</span>
        </span>
        <span className="companyCount">
          <span>Licenses Left</span>
          <span style={{color:"#007749",backgroundColor:"#E2EEFD"}}>{userDetails?.userLicense?.remainingUser}</span>
        </span>
        {PortalUserRole == "ROLE_MANAGER"? <span className="addMore" style={{width:"auto"}} onClick={()=>setOpenPurchaseModal(true)}>+ Add More Licenses</span>:<></>}
      </div>

      <div className="EditJobOuterDiv" style={{width:"97%",margin:"0 auto"}}>
          <div className="EditJobTableSubDiv">
          {teamList.length!=0?<table className="tableStyleMyJobs">
            <thead style={{position: "sticky", top:"0",height:"30px"}}>
                <tr
                  style={{backgroundColor: '#007749', color: '#fff' }}
                >
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderTopLeftRadius:"10px",width:"3%",borderRight:"0.5px solid gray"}}
                    
                  >
                    <span>#</span>
                  </th>
                  
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ borderRight:" 0.5px solid gray", width:"20%"}}
                  >
                    <span>Recruiter Name (LinkedIn)</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"17%"}}
                  >
                    <span>Phone</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"20%"}}
                  >
                    <span>Email</span>
                  </th>
                  {/* <th
                    colspan="1"
                    role="columnheader"                   
                    style={{border:" 0.5px solid gray", width:"20%"}}
                  >
                    <span>Linked In</span>
                  </th> */}
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"10%"}}
                  >
                    <span>Status</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderTopRightRadius:"10px",width:"10%"}}
                  >
                    Action
                  </th>
                </tr>
            </thead>
            <tbody style={{borderBottom:"0.5px solid gray"}}>
              {
                teamList?.map((item,ind)=>{
                    return(
                        <tr key={ind} style={{backgroundColor:ind%2==0?"#C1E7D8":"#ffff"}}>
                            <td className='UMtbodyTd'><span style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{ind + 1}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan' style={{color:!!item?.linkedinProfile? "blue" :"black", cursor:!!item?.linkedinProfile?"pointer":""}} onClick={()=>handleNavigateLinkedIn(item)}>{item?.fullName}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan'>{item?.combineMobilenumber}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan'>{item?.userName}</span></td>
                            {/* <td className='UMtbodyTd'><span className='UMtbodySpan'>{item?.linkedIn}</span></td> */}
                            <td className='UMtbodyTd'>
                              {(item?.status?.toLowerCase() == "active" && !item?.licensed )? <span className="addMore" style={{width:"50px",margin:"0 auto"}} onClick={()=>handleActiveUser(item)}>Activate</span> :<span className='UMtbodySpan' style={{color:item?.status.toLowerCase()=="active"? "green":item?.status.toLowerCase()=="pending"?"#ffb205":"red",fontWeight:500}}>{item?.status}</span>}
                              {/* <span className='UMtbodySpan' style={{color:item?.status.toLowerCase()=="active"? "green":item?.status.toLowerCase()=="pending"?"#ffb205":"red",fontWeight:500}}>{item?.status}</span> */}
                              </td>
                            <td>
                                <span className='UMtbodyActionSpan'>
                                <img title="edit" style={{visibility:item?.status.toLowerCase()=="pending"?"block":"hidden"}} onClick={()=>getUserCompleteDetails(item?.userId)} src={editIcon} alt=''/>
                                <img title='delete' style={{visibility:item?.status.toLowerCase() != "inactive" && ind != 0 ? "block":"hidden"}}  onClick={()=>handleDeleteUser(item)} src={deleteIcon} alt=''/>
                                </span>
                            </td>
                        </tr>
                    )
                })
              }
              
            </tbody>
          </table>
          :
          <div className="noNotificationMsg" style={{height:"50vh"}}>
            <img
              src={nodata}
              className="nodataImage"
            />
            <p>No Data Available.</p>
          </div>
          }
          </div>
          </div>
       {/*    <div style={{ display: "flex", justifyContent: "center" }} className="editJobpagination">
                <ComponentAndScreenConstant.TablePagination
                //   setPageNumber={setSelectedPageNumber}
                //   pagenumber={selectedPageNumber}
                //   totalpages={totalpages}
                //   isSavedPageNumberNeeded={true}
                />
              </div> */}
     <LicenseModal 
        openModal={openPurchaseModal}
        closeModal={setOpenPurchaseModal}
      />
      <Modal
        isOpen={editUMModal}
        onRequestClose={() => setEditUMModal(false)}
        ariaHideApp={false}
        style={customStyles1}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="deleteModalWrapper">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={handleCloseEditModal}>
              ×
            </p>
          </div>
        </div>

        <div className="editProFormOuterDiv">

          <h3>{editUser? "Edit User" : "Add User"}</h3>
          <div className="editProField">
            <label>Full Name<span>*</span></label>
            <div className="editProfileFieldDiv">
              <input type= "text" placeholder='Enter your full name' value={newUserDetails?.fullName?.fieldValue} onChange={(e) => onTextChange("Full Name",e?.target?.value)} />
            </div>
            <p className="errorMsg">{newUserDetails?.fullName?.errorField}</p>

          </div>
          <div className="editProField">
            <label>Mobile No.<span>*</span></label>
            <div className="editProfileFieldDiv userProfileMnoDiv" style={{border:"0.5px solid #007749"}}>
            <select disabled style={{width:"15%",paddingLeft:0}} value={newUserDetails?.cntryCode} onChange={(e) => handleRegInputChange(e, "cntryCode")}>
                {
                  countryCode.map((item) => {
                    console.log("cntry code item",item);
                    return (
                      <option value={item?.countryCodeId} selected={item?.countryCodeId == newUserDetails?.cntryCode}>+{item?.countryCode}</option>
                    )
                  })
                }
              </select>
              <input type="number" placeholder='Enter your mobile no.'  pattern="\d{1,10}" maxLength={"10"} value={newUserDetails?.mno?.fieldValue} onChange={(e) => onTextChange("Mobile Number",e?.target?.value)}/>
           </div>
            <p className="errorMsg">{newUserDetails?.mno?.errorField}</p>
          </div>
          <div className="editProField">
            <label>Email Id<span>*</span></label>
            <div className="editProfileFieldDiv">
            <input type="email" placeholder='Enter your email id' value={newUserDetails?.email?.fieldValue}  onChange={(e) => onTextChange("Email Address",e?.target?.value)}  />
            </div>
            <p className="errorMsg">{newUserDetails?.email?.errorField}</p>
          </div>
          <div className="editProBtn">
            <button className="EditProfCancelBtn" onClick={handleCloseEditModal}>Cancel</button>
            <button className="EdirProfChangeBtn" onClick={() => {
              if(editUser){
                updateUser()
              }else{
                AddUser()
              }
             
              }}>{editUser ? "Update" : "Add"}</button>
          </div>
        </div>

      </Modal>

      <Modal
        isOpen={confirmActivateUser}
        onRequestClose={() => setConfirmActivateUser(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setConfirmActivateUser(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div className='logoutLogoDiv'>
          <img src={confirmationLogo} style={{width:"120px"}} />
        </div>
        <div className="delAccModalErrMsg" style={{ fontWeight: "normal",margin:0,padding:"10px 0 25px 0" }}>
          Are you sure you want to activate this user?
        </div>
        <div className="okBtnModal" style={{ display: "flex", gap: "2%" }}>
          <button onClick={()=>handleActivateUser()}>Yes</button>
          <button onClick={() => setConfirmActivateUser(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #007749", color: "#007749"}}>No</button>
        </div>
      </Modal>

      <Modal
        isOpen={errPopup1}
        onRequestClose={() => setErrPopup1(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup1(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => { setErrPopup1(false) }}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setSuccessPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setSuccessPopup(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>

      <Modal
        isOpen={deletemodalIsOpen}
        onRequestClose={closeDeleteModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="deleteModalWrapper" >
          <div className="delAccModalCrossDiv">
          <img src={closeIcon} alt="Close Icon" style={{cursor:"pointer"}} onClick={closeDeleteModal} />          </div>
        </div>
        <div className="delAccModalErrMsg" style={{margin:0,padding:0}}>
          <img src={deleteIcon2} alt="Delete Icon"/>
          <p>Would you like to delete this recruiter?</p>
          <div>
            <button
              style={{
                padding: "7px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #007749",
                background: 'none',
                color: '#007749'
              }}
              onClick={closeDeleteModal}
            >
              Cancel
            </button>
            <button
              style={{
                padding: "7px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "none",
                color: "#FFF",
                backgroundColor: "#BC0000",
              }}
              onClick={handleConfirmDeleteUser}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>:
    <div style={{width:"100%",height:"85vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
    <img src={nodatafound} style={{width:"20%"}}/>
    <span>Oops! No Page Found</span>
   </div>
    }</>
  )
}

export default UserManagement